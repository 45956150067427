import React from "react"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import enterPriseApplication from "../../assets/icons/noun_application_3279255.svg"
import applicationServices from "../../assets/icons/applicationServices.svg"
import applicationMigration from "../../assets/icons/applicationMigration.svg"
import Service from "../../components/Shared/Service"
import { graphql } from "gatsby"

const seoTitle = "Enterprise Applications "
const mainHeading = "Enterprise Applications"
const seoDescription =
  " Atyeti offers support across the entire Enterprise Software Implementation Lifecycle from subject matter expertise through building (develop, test, and deployment) to global support services."
const keyword =
  "Enterprise Application, Loan IQ, Fenergo, Salesforce, Oracle, Snowflake, Pega, Databricks"
const mainPara = `From subject matter expertise through building (develop, test, and deployment) to global support services, Atyeti offers support across the entire Enterprise Software Implementation Lifecycle.`

const serviceIcons = [
  { id: 1, name: " Loan IQ", icon: checkMark },
  { id: 2, name: "Fenergo", icon: checkMark },
  { id: 3, name: " Salesforce", icon: checkMark },
  { id: 4, name: "Oracle", icon: checkMark },
  { id: 5, name: "Snowflake", icon: checkMark },
  { id: 6, name: "Pega", icon: checkMark },
  { id: 7, name: "Databricks", icon: checkMark },
  { id: 8, name: "Smartstream", icon: checkMark },
]

const section2Heading = `Our Services`
const section2Para = `We implement and maintain large-scale enterprise software packages that support business processes, information flows, reporting, and data analytics in complex organizations.`

const cardItems = [
  {
    id: 1,
    icon: enterPriseApplication,
    heading: `Enterprise Application`,
    listItems: (
      <>
        <li>
          As complexity increases in application landscapes, digital and cloud
          require more IT resources.
        </li>
        <li>
          Atyeti can help you maximize efficiency and performance of your
          application portfolio.
        </li>
      </>
    ),
  },
  {
    id: 2,
    icon: applicationServices,
    heading: `Application Services`,
    listItems: (
      <>
        <li>Identifying the right product/solution.</li>
        <li>Build up a Team, take the COE approach to building capability.</li>
        <li>Agile- Design, Build, Test and Maintain the application.</li>
        <li>Provide integration with other enterprise applications.</li>
        <li>Cost savings through global delivery model.</li>
      </>
    ),
  },
  {
    id: 3,
    icon: applicationMigration,
    heading: `Application Modernization and Migration`,
    listItems: (
      <>
        <li>
          Our services can help you re-architect your Systems with an
          accelerated, efficient approach to transforming legacy platform-based
          enterprise applications.
        </li>
        <li>
          Our services include automated engineering, retooling, staffing and
          supporting ongoing operations.
        </li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    className="enterprise-application"

  />
)
export const query = graphql`
  query EnterpriseApplicationCaseQuery {
    casestudyJson(mainheading: { eq: "enterprise-application" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
